.hero-gallery {
	display: flex;
	position: relative;
	z-index: 2;
	width: fit-content;

	&__item {
		display: flex;
		flex-direction: column;
		gap: 10px;
		box-shadow: 0px 0px 60px -15px rgba(0,0,0,0);
		transition: box-shadow .4s;

		max-width: clamp(200px, 64.84375vw, 593px);

		@include hover {
			box-shadow: 0px 0px 40px -15px rgba(0,0,0,5);
		}

		&:nth-child(2) {
			position: absolute;
			right: 0;
			top: 0;
			transform: translate(70%, -15%);

			max-width: clamp(106px, 30.73vw, 236px);

			@include small-tablet {
				transform: translate(90%, 10%);
			}
		}
	}
}