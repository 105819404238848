.header {

  @include tablet {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 50;
  }


  &__inner {
    padding: clamp(20px, 6.51vw, 50px) 0 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__title {
    color: rgba(var(--cl-c),1.0);
    font-family: var(--font-family);
    font-size: clamp(16px, 3.125vw, 24px);
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
  }
}

.burger {
  @include burger
}

