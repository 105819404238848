.cta-section {


	&__inner {
		padding-top: clamp(20px, 13.54vw, 224px);
		padding-bottom: clamp(50px, 16.40625vw, 224px);
		display: flex;
		align-items: center;
		gap: clamp(20px, 5.21vw, 100px);

		@include mobile {
			flex-direction: column;
			align-items: flex-start;
			width: fit-content;
			margin: 0 auto;
		}
	}

	&__title {
		color: rgba(var(--cl-c),1.0);

		font-family: var(--font-family);
		font-size: clamp(24px, 6.51vw, 100px);
		font-style: normal;
		font-weight: 400;
		line-height: 110%;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;

		@include mobile {
			.accent {
				padding-left: 20px;
				text-transform: lowercase;
			}
		}
	}

	.round {
		max-width: clamp(150px, 23.4375vw, 200px);
		width: 100%;
		padding: 20px;

		@include mobile {
			&:before {
				width: 105%;
				left: -15px;
				top: -15px;
			}
		}
	}
}