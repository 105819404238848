.footer-nav {
	display: flex;
	flex-direction: column;
	gap: clamp(20px, 6.51vw, 50px);

	&__title {
		color: rgba(var(--cl-d),1.0);
		font-family: var(--font-family);
		font-size: clamp(18px, 3.90625vw, 30px);
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		text-transform: uppercase;
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: clamp(15px, 2.6vw, 20px);

		a {
			color: rgba(var(--cl-c),1.0);
			font-family: var(--font-family);
			font-size: clamp(15px, 2.34375vw, 18px);
			font-style: normal;
			font-weight: 400;
			line-height: 110%;
			transition: all .3s;

			@include hover {
				color: rgba(var(--cl-d),0.8);
			}
		}
	}
}