.format-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: clamp(40px, 6.51vw, 75px);

	&__item {
		display: grid;
		grid-template-columns: 1fr repeat(3, auto);
		align-items: center;
		gap: clamp(10px, 2.6vw, 20px) clamp(10px, 2.6vw, 40px);
		width: 100%;



		&[data-avalible] {
			padding-bottom: 10px;
			pointer-events: none;
			user-select: none;
			position: relative;

			&:before {
				position: absolute;
				inset: -5px;
				content: '';
				z-index: 2;
				border: 1px solid var(--br-a);
				background: rgba(238, 237, 238, 0.80);

				@include tablet {
					inset: -10px;
				}
			}

			&:after {
				content: attr(data-avalible);
				position: absolute;
				max-width: 500px;
				z-index: 20;

				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				color: rgba(var(--cl-d),1.0);
				font-family: var(--font-family);
				font-size: clamp(25px, 4.56vw, 37px);
				font-style: normal;
				font-weight: 400;
				line-height: 110%;
				text-transform: uppercase;

				@include tablet {
					text-align: center;
				}
			}
		}


		--icon-size: clamp(43px, 5.6vw, 60px);
		--gap: clamp(10px, 1.953125vw, 45px);

		padding-left: calc(var(--icon-size) + var(--gap));

		@include tablet {
			grid-template-columns: auto auto auto;
			max-width: 85%;
		}

		@include mobile {
			padding: 0;
			max-width: 414px;
		}

		@include ios {
			grid-template-columns: repeat(3, auto);
			justify-content: flex-end;
		}

		&-btn {
			display: flex;
			align-items: center;
			gap: 12px;
			fill: none;

			color: rgba(var(--cl-d),1.0);
			font-family: var(--font-family);
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			text-decoration-line: underline;
			transition: all .3s;

			@include small-tablet {
				font-size: clamp(14px, 2.6vw, 20px);
			}

			svg {
				transition: transform .3s;
			}

			@include hover {
				color: #a9443b;

				svg {
					transform: rotate(-45deg);
				}
			}

			//@include ios {
			//	grid-column: 1/-1;
			//	order: 99;
			//	justify-content: center;
			//}
		}

		&-value {
			display: flex;
			flex-direction: column;
			gap: 5px;
			align-items: flex-end;
			color: rgba(var(--cl-c),1.0);

			&:last-of-type {
				color: rgba(var(--cl-d),1.0);
			}

			font-family:var(--font-family);
			font-size: clamp(14px, 2.6vw, 25px);
			font-style: normal;
			font-weight: 400;
			//line-height: 100%;

			span {
				color: inherit;
				font-family:var(--font-family);
				font-size: clamp(26px, 4.6875vw, 60px);
				font-style: normal;
			}
		}

		p:not([class]) {
			color: rgba(var(--cl-c),1.0);
			font-family:var(--font-family);
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%;

			@include tablet {
				grid-column: 1/-1;
			}
		}
	}

	&__title {
		position: relative;
		color: rgba(var(--cl-c),1.0);
		font-style: normal;
		line-height: 110%;
		text-transform: uppercase;
		grid-column: 1/-1;

		@include mobile {
			gap: 0 10px;
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: auto auto;
		}

		img {
			max-width: var(--icon-size);
			height: auto;

			@include mobile {
				grid-column: 1;
				grid-row: 1/-1;
				align-self: center;
			}

			@include for-mobile {
				top: 0;
				position: absolute;
				left: calc((var(--icon-size) + var(--gap)) * -1);
			}
		}
	}

}