.works-section {


	&__inner {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: clamp(10px, 1.953125vw, 35px);
		padding-bottom: calc(clamp(50px, 10.9375vw, 150px) / 2);

		@include for-small-tablet {
			padding: 0 0 calc(clamp(50px, 10.9375vw, 150px) / 2) 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 40px;

		p {
			color: rgba(var(--cl-c),1.0);
			font-size: clamp(14px, 2.734375vw, 24px);
			font-style: normal;
			font-weight: 400;
			line-height: 160%;
		}

		ul {
			display: flex;
			flex-direction: column;
			gap: 12px;
			list-style: initial;
			padding-left: clamp(25px, 3.90625vw, 40px);
		}

		li {
			color: rgba(var(--cl-c),1.0);
			font-family: var(--font-family);
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			list-style: disc;
			position: relative;

			&::marker {
				color: currentColor;
				font-size: 1.5rem;
			}
		}
	}
}