.numbered-list {
	display: flex;
	flex-direction: column;
	//width: fit-content;
	margin: 0 auto;
	gap: clamp(25px, 6.51vw, 50px);
	max-width: 940px;
	width: 100%;
	padding: 0 clamp(5px, 5.73vw, 46px);
	counter-reset: list-counter;  /* Инициализируем счётчик */
	list-style: none;
	container-type: inline-size;

	@include mobile {
		padding: 0 5px;
	}

	h3, .h3 {

		color: rgba(var(--cl-c),1.0);
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		text-transform: uppercase;

		@include for-laptop {
			white-space: nowrap;
		}

		@container (min-width: 940px) {
				white-space: initial !important;
		}

	}

	p {
		color: rgba(var(--cl-c),1.0);
		font-size: clamp(15px, 2.08vw, 16px);
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		max-width: 675px;
		width: 100%;


	}

	&__item {
		display: grid;
		grid-template-columns: 60px 1fr;
		align-items: center;
		grid-auto-rows: auto;
		gap: clamp(10px, 2.6vw, 22px) clamp(20px, 4.6875vw, 50px);
		position: relative;
		counter-increment: list-counter;

		@include mobile {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 5px;
		}

		@include tablet {
			grid-template-columns: auto 1fr;
		}

		//&:not(:last-of-type) {
			padding-bottom: clamp(20px, 6.51vw, 50px);

			&:after {
				position: absolute;
				width: 100%;
				height: 1px;
				background: var(--border-default);
				content: '';
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);

				@include small-tablet {
					width: 100vw;
				}

				@include mobile {
					width: 95vw;
				}
			}
		//}



		&:before {
			color: rgba(var(--cl-d),1.0);
			font-family: var(--font-family);
			font-size: clamp(16px, 3.90625vw, 30px);
			font-style: normal;
			font-weight: 400;
			line-height: 120%;
			text-transform: uppercase;

			grid-column: 1;
			grid-row: 1 / -1;
			content: counter(list-counter, decimal-leading-zero);
		}
	}

	p {
		grid-column: 2;
	}

}