.diplomas-section {

	&__inner {
		padding: calc(clamp(50px, 10.9375vw, 150px) / 2) 0;
		display: flex;
		flex-direction: column;
		gap: clamp(20px, 5.859375vw, 80px);
	}


	.main-top {
		&__title {
			@include ios {
				display: flex;
				flex-direction: column;
			}
		}
	}

	&__slider {
		position: relative;
		margin-bottom: calc(clamp(30px, 10.42vw, 80px) * 0.5);

		&-image {
			border-radius: 50%;
			display: flex;
			position: absolute;
			z-index: 10;
			opacity: .7;
			top: 0;
			right: 7%;

			@include mobile {
				right: 0;
				transform: translateY(-35%);
			}

			img {
				width: clamp(85px, 23.4375vw, 200px);
				border-radius: 50%;
				aspect-ratio: 1;
			}

			&:before {
				width: 100%;
				height: 100%;
				position: absolute;
				border: 1px solid var(--br-a);
				content: '';
				border-radius: inherit;
				left: 10%;
			}
		}

		.swiper {

			&-container {
				max-width: 85%;
				margin: 0;

				@include mobile {
					max-width: 100%;
				}
			}

			&-slide {
				display: flex;
				justify-content: center;
			}

			&-buttons {
				position: absolute;
				bottom: calc(clamp(30px, 10.42vw, 80px) * -0.5);
				z-index: 10;
				left: 55%;

				@include mobile {
					left: initial;
					right: 10%;
				}
			}
		}

	}

}