[data-btn-modal] {
  svg {
    pointer-events: none;
  }
}

.modal {
  margin: auto;
  transform: scale(.8);
  display: none;

  &.active {
    transform: scale(1);
    display: flex;
  }

  &__box .close {
    color: rgba(var(--cl-d),1.0);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    top: clamp(15px, 3.26vw, 25px);
    right: clamp(15px, 3.26vw, 25px);

    svg {
      pointer-events: none;
      width: clamp(20px, 3.26vw, 25px);
      height: auto;
    }

  }

}
