.footer {


	@include small-tablet {
		border-bottom: 10px solid var(--br-a);
	}


	&__inner {
		border-top: 1px solid var(--br-a);
		padding-top:clamp(49px, 12.24vw, 94px);
		padding-bottom: clamp(50px, 12.37vw, 105px);

		display: grid;
		grid-template-columns: 1fr auto;
		gap: clamp(50px, 13.02vw, 100px) 20px;

		@include tablet {
			grid-template-columns: 100%;
		}

		@include mobile {
			gap: 30px;
		}

	}

	&__social {
		display: flex;
		align-items: center;
		gap: 10px clamp(20px, 2.6vw, 30px);

		@include mobile {
			display: grid;
			grid-template-columns: repeat(4, auto);

			[href^="mailto"] {
				order: 9999;
				grid-column: 1/-1;
			}
		}
	}

	&__bottom {
		grid-column: 1/-1;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: space-between;

		@include tablet {
			margin-top: -25px;
		}

		@include mobile {
			margin-top: 25px;
			flex-direction: column-reverse;
			flex-wrap: nowrap;
		}

		span {
			color: rgba(var(--cl-c),1.0);
			font-family: var(--font-family);
			font-size: clamp(14px, 2.08vw, 16px);
			font-style: normal;
			font-weight: 400;
			line-height: 160%;
		}

		a {
			color: rgba(var(--cl-c),1.0);
			font-family: var(--font-family);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			text-transform: uppercase;

			&[href^="mailto"] {
				text-transform: lowercase;
			}

			@include hover {
				text-decoration: underline;
			}
		}
	}

	.toTop {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
		right: var(--container-offset);
	}

	.main-top {
		gap: 5px;

		&__title {
			color: rgba(var(--cl-d),1.0);
			font-size: clamp(35px, 8.85vw, 68px);
			font-style: normal;
			font-weight: 400;
			line-height: 90%;
			text-transform: capitalize;
		}

		&__subtitle {
			color: rgba(var(--cl-c),1.0);
			font-size: clamp(16px, 3.125vw, 24px);
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			text-transform: uppercase;
		}
	}
}


