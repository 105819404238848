.reviews-section {


	.main-top {

		&__subtitle {
			max-width: 600px;
			.accent {
				text-transform: uppercase;
			}
		}

		.add-review {
			margin-top: Min(2.6vw,30px);
		}
	}

	&__inner {
		position: relative;
		display: flex;
		flex-direction: column;
		//gap: 50px;
		padding: calc(clamp(50px, 10.9375vw, 150px) / 2) 0;
		margin-bottom: calc(clamp(30px, 10.42vw, 80px) * 0.5);
	}

	.swiper {

		&-slide {
			//padding: 20px;
			margin: 0 0px;
			transform: scale(.75);
			transition: transform .4s linear, opacity .3s ease;
			opacity: 0;

			&-visible {
				opacity: 1;
				transform: scale(1);
			}
		}

		&-buttons {
			position: absolute;
			bottom: calc(clamp(30px, 10.42vw, 80px) * -0.5);
			z-index: 10;
			left: 55%;

			@include mobile {
				left: initial;
				right: 10%;
			}
		}
	}
}

.reviews-slider {
	max-width: 700px;
	width: 100%;
	margin-left: auto;
	margin-right: 8%;
	position: relative;
}

.reviews-card {
	padding-top: clamp(25px, 6.51vw, 50px);
	padding-bottom: clamp(20px, 6.51vw, 50px);
	display: grid;
	grid-template-columns: clamp(46px, 16.28vw, 125px) 2fr;
	gap: 15px clamp(13px, 5.859375vw, 50px);
	align-items: flex-start;
	border-bottom: 1px solid var( --border-default);


	@include mobile {
		align-items: center;
		*:not(.reviews-card__name):not(.reviews-card__image) {
			grid-column: 1/-1;
		}
	}

	&__name {
		display: flex;
		align-items: center;
		gap: clamp(13px, 3.90625vw, 30px);

		svg {
			width: clamp(25px, 6.640625vw, 51px);
			height: auto;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		gap: 24px;

		@include mobile {
			display: contents;
		}
	}

	&__type {
		margin-top: clamp(10px, 2.6vw, 20px);
	}
}