.info-box {
	display: flex;
	flex-direction: column;
	max-width: 180px;
	align-items: center;
	gap: 10px;
	position: relative;


	p {
		color: rgba(var(--cl-c),1.0);
		font-family: var(--font-family);
		font-size: 9px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		padding: 0 10px;
		width: 100%;

		br:last-of-type {
			display: none;
		}
	}

	&__image {
		max-width: clamp(90px, 18.23vw, 180px);
	}

	.round {
		position: absolute;
		bottom: 12%;
		right: -6%;
		transform: translateX(70%);
	}
}