.custom-checkbox {
  position: relative;

  &[data-gty] {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: attr(data-gty);
      color: #8A8A8A;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
    }
  }


  input {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
  }

  span {
    cursor: pointer;
    position: relative;
    z-index: 2;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    user-select: none;
    color: rgba(var(--cl-f),1.0);

    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    //@include mobile {
    //  font-size: 12px;
    //}


    a {
      color: rgba(var(--cl-f),1.0);

      @include hover {
        text-decoration: underline;
      }

    }



    &::before {
      cursor: pointer;
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 4px;
      border: 2px solid #C1C2C7;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px 7.5px;
    }
  }

  input {
    &:not(:disabled) {
      &:not(:checked) + span {
        @include hover {
          &:before {
            border-color: #2458D3;
          }
        }
      }

      &:active + span::before {
        background-color: #2458D3;
        border-color: #2458D3;
      }
    }


    &:checked + span::before {
      border-color: #2458D3;
      background-color: #2458D3;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' fill='none'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-width='.8' d='M4.7 8.6c-.2 0-.3 0-.4-.2l-3.1-3a.6.6 0 1 1 .8-.9l2.7 2.7 5.3-6a.6.6 0 0 1 .9.8L5 8.4a.6.6 0 0 1-.4.2Z'/%3E%3C/svg%3E");
    }

    &:disabled + span::before {
      background-color: #e9ecef;
    }
  }
}

