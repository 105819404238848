.main-top {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: fit-content;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 420px;
    margin: 0 auto;
  }

  &__title {
    color: rgba(var(--cl-c),1.0);
    font-family: var(--font-family);
    font-size: clamp(24px, 5.859375vw, 74px);
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;

  }

  &__subtitle {
    color: rgba(var(--cl-c),1.0);
    font-family: var(--font-family);

    font-size: clamp(16px, 3.39vw, 30px);
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
  }

  &__text {
    color: rgba(var(--cl-c),1.0);
    font-family: var(--font-family);
    font-size: clamp(14px,2.734375vw, 24px);
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 38.4px */
  }

}

