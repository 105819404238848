.hero-section {
	position: relative;
	padding-bottom: clamp(20px, 2.6vw, 50px);

	&__inner {
		padding-top: 40px;
		position: relative;

		display: flex;
		flex-direction: column;
		gap: 40px;

		@include mobile {
			gap: 15px;
		}

		@include tablet {
			padding-top: 100px;
		}
	}

	.container {
		display: grid;
		grid-template-columns: 1.08fr 1fr;
		gap: 20px;

		@include laptop {
			grid-template-columns: 100%;
		}
	}

	&__image {
		display: flex;
		position: relative;
		margin-left: calc(Min(10vw, 130px) * -.5);

		max-height: clamp(20px, 103.90625vw, 920px);
		margin-bottom: 95px;

		@include laptop {
			margin-right: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
			max-width: clamp(258px, 57.29vw, 750px);
		}

		@include mobile {
			max-height: clamp(385px, 50.13vw, 920px);
			transform: translateY(-10%);
		}

		&-label {
			display: flex;
			position: absolute;
			bottom: -95px;
			right: 21%;
			align-items: flex-end;
			gap: 5px;

			@include tablet {
				display: none;
			}
			span {
				margin-bottom: 25px;
				line-height: 160%;
				color: rgba(var(--cl-c),1.0);
				font-family:var(--font-family);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
			}
		}
	}



	.main-top {
		display: flex;
		flex-direction: column;
		gap: 5px;

		@include mobile {
			align-items: flex-start;
		}

		&__title {
			margin-bottom: 10px;

			border-bottom: 3px solid var(--br-a);

			color: rgba(var(--cl-d),1.0);
			font-family: var(--font-family2);
			font-size: clamp(25px, 5.46875vw, 50px);

			width: fit-content;
			text-transform: initial;
		}

		&__wrapper {
			width: fit-content;
			max-width: 80%;
			margin: 0;

			@include tablet {
				padding: 6px;
				background: rgba(var(--bg-e),0.5);
			}

		}

		&__text {
			font-size: clamp(12px, 1.953125vw, 16px);
		}
	}

	.info-box {
		pointer-events: none;
		margin-top: clamp(30px, 13.02vw, 125px);

		@include tablet {
			align-self: center;
			flex-direction: row;
			display: flex;
			max-width: Max(60%, 280px);
			width: 100%;
			margin-top: 50px;
			margin-bottom: calc(clamp(74px, 18.23vw, 140px) * .90);

			&__image {
				grid-row: 1/-1;
				display: flex;
				flex-shrink: 0;
			}

			p {
				font-size: clamp(10px, 2.08vw, 16px);
				position: relative;
				z-index: 10;
				padding: 0;

				background: rgba(var(--bg-e),0.6);

				@include small-tablet {
					z-index: 1;
				}
			}

			*:not(.info-box__image) {
				grid-column: 2;
			}

			.round {
				z-index: 1;
				bottom: calc(clamp(74px, 18.23vw, 140px) * -.90);
				//left: 20%;
				left: Max(5px,6.51vw);
				right: initial;
				transform: translate(0, 0);

				@include mobile {
					left: 20px;
				}
			}
		}
	}

}