.select {
  position: relative;
  //z-index: 100;
  user-select: none;
  width: 100%;

  &.sorting-select {
    width: fit-content;
    min-width: 220px;
    margin-right: -15px;

    @include mobile {
      min-width: 180px;
      margin-right: 0;
    }
  }

  &__header {
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    gap: 15px;

    display: flex;
    align-items: center;
    padding: 0 15px;
    position: relative;
    z-index: 6;

    @include mobile {
      padding-right: 0;
    }

    & > svg {
      transition: transform .4s linear;
      flex-shrink: 0;

      width: 16px;
      aspect-ratio: 1;

      @include mobile {
        width: 14px;
      }
    }
  }

  &__current {
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 16px;
    font-family: var(--font-family);
    line-height: 24px;
    color: rgba(var(--cl-f),1.0);

    @include mobile {
      font-size: 14px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__body {
    width: 100%;
    position: absolute;
    z-index: 5;
    height: 0;
    top: calc(100% + 10px);
    overflow: hidden;
    transition: height 0.2s ease-in;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.0);
    border-radius: 12px;

    i {
      margin: 0;
      text-transform: uppercase;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__list {
    // border: 1px solid var(--br-a);
    padding: 10px 0;
    max-height: 250px;
    overflow-y: auto;
    background: #FFF;


    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
    }
  }

  &__option {
    display: flex;
    padding: 10px clamp(15px, 2.61vw, 30px) 10px clamp(15px, 2.61vw, 30px);
    align-items: center;
    grid-gap: 5px;
    width: 100%;
    cursor: pointer;

    color: rgba(var(--cl-f),1.0);
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 100%;

    & + & {
      padding-top: 10px;
    }

    &:hover {
      //background: rgba(var(--bg-d),1.0);
      font-weight: 600;
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

// .select.active .select__body {
//   height: auto;
// }

.select.active {
  z-index: 3;


  .select__body {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .select__header > svg {
    transform: scaleY(-1);
  }
}

//.default-select {
//  border-radius: 44px;
//  border: 1px solid var(--br-d);
//  background: rgba(var(--bg-a),1.0);
//
//  .select__current[data-id="0"] {
//    color: rgba(var(--cl-e),1.0);
//  }
//
//  &.active {
//    border-color: var(--br-b);
//  }
// }
