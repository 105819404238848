.mobile-menu {
	position: fixed;
	z-index: 100;
	inset: 0;
	display: flex;
	//align-items: center;
	justify-content: center;
	background-color: rgba(var(--bg-e),1.0);
	overflow: auto;
	overflow-x: hidden;
	//padding: 10vh 0;
	transition: all .3s ease-out;
	transform: translateY(-100%);
	border-radius: 0 0 20% 20%;
	opacity: 0;

	&__close {
		position: absolute;
		right: clamp(15px, 3.26vw, 25px);
		top: clamp(15px, 3.26vw, 25px);
	}

	&.active {
		opacity: 1;
		transform: translate(0%);
		border-radius: 0 0 0px 0px;

		&:before {

		}
	}

	&:before {
		width:clamp(100px, 19.53125vw, 150px);;
		aspect-ratio: 1;
		display: flex;
		border-radius: 50%;
		content: '';
		border: 1px solid var(--br-a);
		position: absolute;
		right: 0;
		top: 0;
		transition: all .4s;
		transform: translate(calc(clamp(15px, 3.26vw, 25px) + 0%), calc((clamp(15px, 3.26vw, 25px) * -1.6)));
	}

	&__list {
		align-self: center;
		counter-reset: list-counter;  /* Инициализируем счётчик */
		list-style: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: clamp(30px, 5.859375vw, 60px);
	}

	&__item {
		counter-increment: list-counter;
		position: relative;

		a {
			position: relative;
			color: currentColor;

			display: flex;
			align-items: center;
			gap: clamp(25px, 5.21vw, 50px);

			font-family: var(--font-family);
			font-size: clamp(20px, 3.26vw, 30px);
			font-style: normal;
			font-weight: 400;
			line-height: 110%; /* 35.2px */
			text-transform: uppercase;
			transition: all .4s;

			&:before {
				color: currentColor;
				font-family: var(--font-family);
				font-size: clamp(16px, 3.90625vw, 20px);
				font-style: normal;
				font-weight: 400;
				line-height: 120%;
				text-transform: uppercase;

				content: counter(list-counter, decimal-leading-zero);
			}

			@include hover {
				color: rgba(var(--cl-d),1.0);
				transform: scale(1.1);
			}

			&.active {
				color: rgba(var(--cl-d),1.0);
			}
		}
	}
}