@keyframes pulse {
  0%, 100% {
    box-shadow: 0px 0px 0px 0px rgba(219, 87, 77, 0.6);
  }
  50% {
    box-shadow: 0px 0px 45px 0px rgba(219, 87, 77, 0.8);
  }
}

button.round {
  animation: pulse .9s ease-out infinite;
}

.round {
  width: clamp(74px, 18.23vw, 140px);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color:rgba(var(--bg-d),1.0);
  box-shadow: 0px 0px 0px 0px rgba(219, 87, 77, 0.6) ;
  padding: 5px;
  position: relative;

  color: rgba(var(--cl-a),1.0);
  text-align: center;
  font-family: var(--font-family);
  font-size: clamp(13px, 2.08vw, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-transform: uppercase;
  transition: all .3s;



  @include hover {
    animation-play-state: paused;
    background-color:rgba(var(--bg-d),0.9);
    //box-shadow: 0px 0px 0px 0px rgba(219, 87, 77, 0.9) !important;
    &:before {
      width: 110%;
    }
  }

  &:before {
    transition: all .3s;
    width: 115%;
    aspect-ratio: 1;
    position: absolute;
    background: transparent;
    border-radius: 50%;
    content: '';
    border: 1px solid var(--br-a);
    left: 0;
  }

  img {
    max-width: clamp(21px, 5.21vw, 40px);
    height: auto;
  }
}

.arrow-button {
  width: clamp(30px, 10.42vw, 80px);
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  fill: transparent;
  color: rgba(var(--cl-c),0.4);
  border: 1px solid rgba(16, 16, 16, 0.60);
  background: rgba(var(--bg-e),1.0);
  transition: all .4s;

  &.swiper-button-disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  @include hover {
    color: rgba(var(--cl-c),1.0);
  }

  svg {
    max-width: 50px;
    width: 100%;
    height: auto;
  }
}

.swiper-buttons {
  display: flex;
  align-items: center;
  gap: 3px;
}

.add-review {
  fill: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 20px;
  border-radius: 100px;
  width: fit-content;
  color: rgba(var(--cl-d),1.0);
  border: 1px solid var(--br-a);

  font-family: var(--font-family);
  font-size: clamp(15px, 2.34375vw, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  transition: all .3s;

  svg {
    transition: transform .3s;
  }

  @include hover {
    background-color: rgba(var(--bg-d),1.0);
    color: rgba(var(--cl-a),1.0);
    box-shadow: 0px 0px 45px 0px rgba(219, 87, 77, 0.8);
    border: 1px solid rgba(219, 87, 77, 0.8);

    svg {

      transform: rotate(-90deg);
    }
  }
}

.toTop {
  width: clamp(65px, 13.02vw, 145px);
  aspect-ratio: 1;
  background-color: rgba(var(--bg-d),1.0);
  display: flex;
  align-items: center;
  justify-content: center;
  fill: transparent;
  color: rgba(var(--cl-a),1.0);
  border-radius: 50%;
  transition: all .3s;

  svg {
    width: 80%;
    height: auto;
    transition: all .3s;
    transform: rotate(90deg);
  }

  @include hover {
    box-shadow: 0px 0px 45px 0px rgba(219, 87, 77, 0.8);

    svg {
      transform: rotate(90deg) scale(1.1);
    }
  }
}

.main-button {
  width: 100%;
  border-radius: 15px;
  background: rgba(var(--bg-d),1.0);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: clamp(50px, 8.46vw, 95px);

  color: rgba(var(--cl-a),1.0);
  font-family: var(--font-family);
  font-size: clamp(16px, 2.6vw, 25px);
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--br-a);
  transition: all .3s;

  @include hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
    border-color: var(--br-a);
    background-color: rgba(var(--bg-e),1.0);
    color: rgba(var(--cl-d),1.0);
  }

  &--border {
    background-color: rgba(var(--bg-e),1.0);
    color: rgba(var(--cl-d),1.0);

    @include hover {
      background-color: rgba(var(--bg-d),1.0);
      color: rgba(var(--cl-a),1.0) ;
    }
  }

}