// --------------- hex to rgba mixin ---------------
@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  // --------------- default global variables ---------------
  --frame-width: 1920;

  --rem-function: 16px;
  //--rem-function: calc(100vw / var(--frame-width));

  --error-bg: #d01f1f;
  --body-bg: #EEEDEE;
  --body-font-size: 16px;
  --content-width: 1160px;

  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // --------------- default JS variables ---------------

  //--header-height: 88px;


  // --------------- default scroll variables ---------------
  --scrollbar-thumb: #1397D6;
  // --scrollbar-thumb-hover: #00aff0;
  --scrollbar-track: rgba(0, 0, 0, 0.07);
  --scrollbar-track-hover: transparent;
  --overlay-bg: rgba(0, 0, 0, 0.5);

  // --------------- default select variables ---------------
  --select-inner-bg: #404040;
  --select-inner-border: #FDFDFD;
  --select-focus-border: #FDFDFD;

  --main-button-size: 50px;

  // --------------- default font-family variables ---------------

  --font-family: "ABeeZee", sans-serif;        // var(--font-family);
  --font-family2: "Clash Display", sans-serif; // var(--font-family2);

  // --------------- default text color variables ---------------
  --cl-a: #{HexToRGB(#ffffff)}; // rgba(var(--cl-a),1.0);
  --cl-b: #{HexToRGB(#000000)}; // rgba(var(--cl-b),1.0);
  --cl-c: #{HexToRGB(#101010)}; // rgba(var(--cl-c),1.0);
  --cl-d: #{HexToRGB(#DB574D)}; // rgba(var(--cl-d),1.0);
  --cl-e: #{HexToRGB(#EEEDEE)}; // rgba(var(--cl-e),1.0);
  --cl-f: #{HexToRGB(#0075FF)}; // rgba(var(--cl-f),1.0);



  // --------------- default background color variables ---------------
  --bg-a: #{HexToRGB(#ffffff)}; // rgba(var(--bg-a),1.0);
  --bg-b: #{HexToRGB(#000000)}; // rgba(var(--bg-b),1.0);
  --bg-c: #{HexToRGB(#101010)}; // rgba(var(--bg-c),1.0);
  --bg-d: #{HexToRGB(#DB574D)}; // rgba(var(--bg-d),1.0);
  --bg-e: #{HexToRGB(#EEEDEE)}; // rgba(var(--bg-e),1.0);
  --bg-f: #{HexToRGB(#0075FF)}; // rgba(var(--bg-f),1.0);

  --border-default: rgba(16, 16, 16, 0.10);
  --border-default2: rgba(16, 16, 16, 0.6);




  // --------------- default border color variable ---------------
  --br-a: #DB574D; // var(--br-a);



  // --------------- default projects variables ---------------


}


