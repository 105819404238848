.form-modal {
	border-radius: 15px;
	width: 90vw;
	max-width: 610px;
	padding: clamp(20px, 9.765625vw, 75px) clamp(20px, 4.56vw, 55px);
	background-color: rgba(var(--cl-e), 1.0);
	flex-direction: column;
	gap: clamp(20px, 5.21vw, 40px);

	--adp-width: 100%;

	&__link {
		color: rgba(16, 16, 16, 0.60);
		font-family: var(--font-family);
		font-size: clamp(16px, 2.86vw, 22px);
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: -0.44px;
		text-decoration-line: underline;
		transition: all .4s;

		@include hover {
			text-decoration-line: none;
			color: rgba(16, 16, 16, .8);
		}
	}

	&__descr {
		color: rgba(16, 16, 16, 0.60);
		font-family: var(--font-family);
		font-size: clamp(16px, 2.86vw, 22px);
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: -0.44px;
	}

	&__bottom {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: column;
		gap: clamp(25px, 5.859375vw, 45px);
	}

	&--mode {
		gap: clamp(25px, 5.859375vw, 45px);

		.form-modal__title {
			align-items: center;
			display: flex;
			flex-direction: column;
			gap: clamp(25px, 5.859375vw, 50px);
			text-align: center;
			line-height: 120%;
		}

		.form-modal__top p {
			font-size: clamp(18px, 3.65vw, 28px);
			line-height: 100%;

			span {
				font-size: clamp(14px, 2.34375vw, 18px);
				line-height: 90%;
			}
		}
	}

	&__top {
		display: flex;
		flex-direction: column;
		gap: 20px;

		p {
			color: rgba(var(--cl-c), 1.0);
			font-family: var(--font-family);
			font-size: clamp(16px, 2.6vw, 20px);
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			letter-spacing: -0.4px;

			a {
				color: rgba(var(--cl-f), 1.0);

				@include hover {
					color: rgba(var(--cl-f), 0.6)
				}
			}
		}
	}

	&__title {
		color: rgba(var(--cl-d), 1.0);
		font-family: var(--font-family);
		font-size: clamp(20px, 5.859375vw, 45px);
		font-style: normal;
		font-weight: 400;
		line-height: 90%;
		letter-spacing: -0.9px;
		text-transform: uppercase;

		i {
			font-style: inherit;
			font-size: clamp(16px, 3.26vw, 25px);
		}
	}

	&__radio {
		display: grid;
		grid-template-columns: repeat(4, auto);
		gap: 20px 10px;

		@include mobile {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&__row {
		display: flex;
		flex-direction: column;
		gap: clamp(10px, 2.6vw, 20px);

		p {

			* {
				text-transform: lowercase;
			}

			text-transform: lowercase;
			padding: 0 clamp(15px, 3.515625vw, 27px);
			
			
			display: flex;
			flex-direction: column;
			gap: 10px;

			color: rgba(var(--cl-c), 0.6);
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 90%;
			letter-spacing: -0.3px;
		}

		&.padding {
			.air-datepicker,
			input {
				max-width: 420px;
				margin: 0 auto;
			}

			.just-validate-error-label {
				max-width: 420px;
				margin:  calc(clamp(10px, 2.6vw, 20px) / -2) auto;
			}
		}
	}

	&__legend {
		color: rgba(var(--cl-d), 1.0);
		font-family: var(--font-family);
		font-size: clamp(16px, 3.125vw, 24px);
		font-style: normal;
		font-weight: 400;
		line-height: 110%;
		letter-spacing: -0.48px;
		text-transform: uppercase;
	}

	.input-data {
		width: 100%;
		border-bottom: 1px solid rgba(16, 16, 16, 0.6);

		font-family: var(--font-family);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%;
		padding: 10px 0;
		letter-spacing: -0.36px;
	}

	.default-input {
		height: clamp(55px, 8.46vw, 65px);
		border-radius: 15px;
		border: 1px solid var(--border-default2);
		font-family: var(--font-family);
		padding: clamp(12px, 1.953125vw, 15px) clamp(15px, 3.515625vw, 27px);
		font-size: clamp(15px, 2.34375vw, 18px);
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 16.2px */
		letter-spacing: -0.36px;
		background-color: transparent;
		display: flex;
		align-items: center;

		&::placeholder {
			color: var(--border-default2);
		}

		&[name='review'] {

			height: initial;
		}

	}

	&__radio + textarea {
		padding: clamp(12px, 1.953125vw, 15px) clamp(15px, 3.515625vw, 27px) 0 !important;
	}

	.custom-radio {
		span {
			color: rgba(16, 16, 16, 0.60);
			font-family: var(--font-family);
			font-size: clamp(15px, 2.34375vw, 18px);
			font-style: normal;
			font-weight: 400;
			line-height: 90%; /* 16.2px */
			letter-spacing: -0.36px;

			&:before {
				border-color: #000 !important;
				background-color: #D9D9D9 !important;
			}
		}
	}

	&__box {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.radio {
		cursor: pointer;

		&__title {
			color: rgba(var(--cl-b),1.0);
			font-family: var(--font-family);
			font-size: clamp(18px, 2.734375vw, 21px);
			font-style: normal;
			font-weight: 400;
			line-height: 90%;
			letter-spacing: -0.42px;
		}

		&__inner {
			display: grid;
			grid-template-columns: 2fr 1fr;
			width: 100%;
			justify-content: space-between;
			align-items: flex-start;
			border: 1px solid rgba(var(--cl-e),1.0);
			border-radius: 15px;
			background: rgba(var(--cl-e),1.0);
			box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10);


			&-name {
				color: rgba(16, 16, 16, 0.60);
				font-family: var(--font-family);
				font-size: clamp(16px, 2.34375vw, 18px);
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				letter-spacing: -0.36px;
			}

			&-value {
				color: rgba(16, 16, 16, 0.45);
				font-family: var(--font-family);
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 110%;
				letter-spacing: -0.36px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				i {
					font-style: inherit;
				}
			}

			&-list {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}

			&-item {
				display: flex;
				flex-direction: column;
			}

			&-content {
				display: flex;
				flex-direction: column;
				gap: 10px;
				padding: clamp(15px, 3.90625vw, 30px) 15px clamp(15px, 3.90625vw, 30px) clamp(15px, 3.90625vw, 30px);
			}
		}

		img {
			display: flex;
			height: 100%;
			border-radius: 15px;
			width: 100%;
		}


		input:checked + .radio__inner {
			border-color: rgba(var(--bg-d),1.0);
		}

		img {
			grid-column: 2;
			grid-row: 1/-1;
		}

		input {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			z-index: -1;
		}
	}
}

.air-datepicker {
	order: 99;
	width: 100%;
	background-color: rgba(var(--bg-d), 1.0);
	box-shadow: 0px 10px 63px -20px rgba(0, 0, 0, 0.75) !important;

	&-body {
		background-color: rgba(var(--bg-e), 1.0);
	}

	&-nav,
	&--buttons {
		border: none;
	}

	&-nav--title,
	&-nav--title i,
	&-nav--action path,
	&-button {
		stroke: currentColor;
		color: rgba(var(--cl-a), 1.0);
	}


	&-cell {
		border-radius: 0;

		&.-selected-.-current- {
			color: rgba(var(--cl-a), 1.0);
			background-color: rgba(var(--bg-d), 1.0);
		}
	}

	.-day- {
		border-collapse: collapse;
		background-color: rgba(var(--cl-c), 0.05);
		border: 1px solid var(--border-default);

		&.-weekend- {
			background-color: rgba(var(--cl-c), 0.15);
		}

		&.-current- {
			color: rgba(var(--cl-d), 1.0);
		}

		&.-selected- {
			color: rgba(var(--cl-a), 1.0);
			background-color: rgba(var(--bg-d), 1.0);
		}


	}
}

[data-popup="fail"] {
	.round {
		img {
			transform: scaleY(-1);
		}
	}
}