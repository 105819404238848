h1,.h1 {
  font-size: clamp(25px, 5.46875vw, 50px);
  font-weight: 400;
  line-height: 100%;
}

h2, .h2 {
  font-size: clamp(20px, 4.17vw, 40px);
  font-weight: 400;
  line-height: 110%;
  font-family: var(--font-family);
}

h3, .h3 {
  font-size: clamp(16px, 3.125vw, 25px);
  font-weight: 400;
}

h4, .h4 {
  font-size: clamp(18px, 2.813vw, 20px);
  font-weight: 600;
}

.accent {
  text-transform: capitalize;
  color: rgba(var(--cl-d),1.0);
}


