$color_1: var(--adp-color-other-month);
$color_2: var(--adp-color-other-month-hover);
$color_3: #fff;
$color_4: var(--adp-color);
$color_5: var(--adp-color-current-date);
$color_6: var(--adp-color-disabled);
$color_7: var(--adp-color-disabled-in-range);
$color_8: var(--adp-day-name-color);
$color_9: var(--adp-day-name-color-hover);
$color_10: var(--adp-nav-color-secondary);
$color_11: var(--adp-btn-color);
$color_12: var(--adp-btn-color-hover);
$color_13: var(--adp-time-day-period-color);
$color_14: rgba(0, 0, 0, 0);
$font-family_1: var(--font-family);
$font-family_2: var(--font-family);
$background-color_1: var(--adp-background-color-in-range);
$background-color_2: var(--adp-background-color-in-range-focused);
$background-color_3: var(--adp-cell-background-color-in-range);
$border-color_1: var(--adp-time-track-color-hover);
$border-color_2: var(--adp-cell-background-color-selected);
$border-color_3: var(--adp-border-color-inline);

.air-datepicker-cell.-year-.-other-decade- {
	color: $color_1;
	&:hover {
		color: $color_2;
	}
	&:empty {
		background: none;
		border: none;
	}
}
.air-datepicker-cell.-day-.-other-month- {
	color: rgba(var(--cl-c), 0.25);

	&:hover {
		color: $color_2;
	}
	&:empty {
		background: none;
		border: none;
	}
}
.-disabled-.-focus-.air-datepicker-cell.-year-.-other-decade- {
	color: $color_1;
}
.-disabled-.-focus-.air-datepicker-cell.-day-.-other-month- {
	color: $color_1;
}
.-selected-.air-datepicker-cell.-year-.-other-decade- {
	color: $color_3;
	background: var(--adp-background-color-selected-other-month);
}
.-selected-.air-datepicker-cell.-day-.-other-month- {
	color: $color_3;
	background: var(--adp-background-color-selected-other-month);
}
.-selected-.-focus-.air-datepicker-cell.-year-.-other-decade- {
	background: var(--adp-background-color-selected-other-month-focused);
}
.-selected-.-focus-.air-datepicker-cell.-day-.-other-month- {
	background: var(--adp-background-color-selected-other-month-focused);
}
.-in-range-.air-datepicker-cell.-year-.-other-decade- {
	background-color: $background-color_1;
	color: $color_4;
}
.-in-range-.air-datepicker-cell.-day-.-other-month- {
	background-color: $background-color_1;
	color: $color_4;
}
.-in-range-.-focus-.air-datepicker-cell.-year-.-other-decade- {
	background-color: $background-color_2;
}
.-in-range-.-focus-.air-datepicker-cell.-day-.-other-month- {
	background-color: $background-color_2;
}
.air-datepicker-cell {
	border-radius: var(--adp-cell-border-radius);
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	z-index: 1;
}
.air-datepicker-cell.-focus- {
	background: var(--adp-cell-background-color-hover);
}
.air-datepicker-cell.-current- {
	color: $color_5;
}
.air-datepicker-cell.-current-.-focus- {
	color: $color_4;
}
.air-datepicker-cell.-current-.-in-range- {
	color: $color_5;
}
.air-datepicker-cell.-disabled- {
	cursor: default;
	color: $color_6;
}
.air-datepicker-cell.-disabled-.-focus- {
	color: $color_6;
}
.air-datepicker-cell.-disabled-.-in-range- {
	color: $color_7;
}
.air-datepicker-cell.-disabled-.-current-.-focus- {
	color: $color_6;
}
.air-datepicker-cell.-in-range- {
	background: var(--adp-cell-background-color-in-range);
	border-radius: 0;
	&:hover {
		background: var(--adp-cell-background-color-in-range-hover);
	}
}
.air-datepicker-cell.-range-from- {
	border: 1px solid var(--adp-cell-border-color-in-range);
	background-color: $background-color_3;
	border-radius: var(--adp-cell-border-radius) 0 0 var(--adp-cell-border-radius);
}
.air-datepicker-cell.-range-to- {
	border: 1px solid var(--adp-cell-border-color-in-range);
	background-color: $background-color_3;
	border-radius: 0 var(--adp-cell-border-radius) var(--adp-cell-border-radius) 0;
}
.air-datepicker-cell.-range-to-.-range-from- {
	border-radius: var(--adp-cell-border-radius);
}
.air-datepicker-cell.-selected- {
	color: $color_3;
	border: none;
	background: var(--adp-cell-background-color-selected);
}
.air-datepicker-cell.-selected-.-current- {
	color: $color_3;
	background: var(--adp-cell-background-color-selected);
}
.air-datepicker-cell.-selected-.-focus- {
	background: var(--adp-cell-background-color-selected-hover);
}
.air-datepicker-body {
	transition: all var(--adp-transition-duration) var(--adp-transition-ease);
}
.air-datepicker-body.-hidden- {
	display: none;
}
.air-datepicker-body--day-names {
	display: grid;
	grid-template-columns: repeat(7, var(--adp-day-cell-width));
	//margin: 8px 0 3px;
}
.air-datepicker-body--day-name {
	color: rgba(var(--cl-c),1.0);
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	text-align: center;
	text-transform: uppercase;
	font-size: .8em;
	line-height: 100%;
	aspect-ratio: 2/1;
	font-weight: 600;
	border: 1px solid rgba(16, 16, 16, 0.10);
}
.air-datepicker-body--day-name.-clickable- {
	cursor: pointer;
	&:hover {
		color: $color_9;
	}
}
.air-datepicker-body--cells {
	display: grid;
}
.air-datepicker-body--cells.-days- {
	grid-template-columns: repeat(7, var(--adp-day-cell-width));
	grid-auto-rows: var(--adp-day-cell-height);
}
.air-datepicker-body--cells.-months- {
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: var(--adp-month-cell-height);
}
.air-datepicker-body--cells.-years- {
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: var(--adp-year-cell-height);
}
.air-datepicker-nav {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--adp-border-color-inner);
	min-height: var(--adp-nav-height);
	padding: var(--adp-padding);
	box-sizing: content-box;
}
.-only-timepicker- {
	.air-datepicker-nav {
		display: none;
	}
	.air-datepicker-time {
		border-top: none;
	}
	.air-datepicker--content {
		display: none;
	}
}
.air-datepicker-nav--title {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	border-radius: var(--adp-border-radius);
	padding: 0 8px;
	color: rgba(var(--cl-a),1.0);

	i {
		font-style: normal;
		color: currentColor;
		margin-left: .3em;
	}
	&:hover {
		color: rgba(var(--cl-a),1.0);
		background: var(--adp-background-color-hover);

		i {
			color: currentColor;
		}
	}
	&:active {
		background: var(--adp-background-color-active);
	}
}
.air-datepicker-nav--action {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	width: var(--adp-nav-action-size);
	border-radius: var(--adp-border-radius);
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;



	&:hover {
		background: var(--adp-background-color-hover);

		path {
			color: rgba(var(--cl-d),1.0);
		}
	}
	//&:active {
	//	background: var(--adp-background-color-active);
	//}
	svg {
		width: 32px;
		height: 32px;
	}
	path {
		fill: none;
		stroke: var(--adp-nav-arrow-color);
		stroke-width: 2px;
	}
}
.air-datepicker-nav--action.-disabled- {
	visibility: hidden;
}
.air-datepicker-nav--title.-disabled- {
	cursor: default;
	background: none;
}
.air-datepicker-buttons {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
}
.air-datepicker-button {
	display: inline-flex;
	color: $color_11;
	border-radius: var(--adp-btn-border-radius);
	cursor: pointer;
	height: var(--adp-btn-height);
	border: none;
	background: rgba(255, 255, 255, 0);
	&:hover {
		color: $color_12;
		background: var(--adp-btn-background-color-hover);
	}
	&:focus {
		color: $color_12;
		background: var(--adp-btn-background-color-hover);
		outline: none;
	}
	&:active {
		background: var(--adp-btn-background-color-active);
	}
	span {
		outline: none;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
}
.air-datepicker-time {
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-column-gap: 12px;
	align-items: center;
	position: relative;
	padding: 0 var(--adp-time-padding-inner);
}
.air-datepicker-time--current {
	display: flex;
	align-items: center;
	flex: 1;
	font-size: 14px;
	text-align: center;
}
.air-datepicker-time--current-colon {
	margin: 0 2px 3px;
	line-height: 1;
}
.air-datepicker-time--current-hours {
	line-height: 1;
	font-size: 19px;
	font-family: $font-family_1;
	position: relative;
	z-index: 1;
	&:after {
		content: "";
		background: var(--adp-background-color-hover);
		border-radius: var(--adp-border-radius);
		position: absolute;
		left: -2px;
		top: -3px;
		right: -2px;
		bottom: -2px;
		z-index: -1;
		opacity: 0;
	}
}
.air-datepicker-time--current-minutes {
	line-height: 1;
	font-size: 19px;
	font-family: $font-family_1;
	position: relative;
	z-index: 1;
	&:after {
		content: "";
		background: var(--adp-background-color-hover);
		border-radius: var(--adp-border-radius);
		position: absolute;
		left: -2px;
		top: -3px;
		right: -2px;
		bottom: -2px;
		z-index: -1;
		opacity: 0;
	}
}
.air-datepicker-time--current-hours.-focus- {
	&:after {
		opacity: 1;
	}
}
.air-datepicker-time--current-minutes.-focus- {
	&:after {
		opacity: 1;
	}
}
.air-datepicker-time--current-ampm {
	text-transform: uppercase;
	align-self: flex-end;
	color: $color_13;
	margin-left: 6px;
	font-size: 11px;
	margin-bottom: 1px;
}
.air-datepicker-time--row {
	display: flex;
	align-items: center;
	font-size: 11px;
	height: 17px;
	background: linear-gradient(to right, var(--adp-time-track-color), var(--adp-time-track-color)) left 50%/100% var(--adp-time-track-height) no-repeat;
	&:first-child {
		margin-bottom: 4px;
	}
	input[type=range] {
		background: none;
		cursor: pointer;
		flex: 1;
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		-webkit-appearance: none;
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			box-sizing: border-box;
			height: 25px;
			width: 25px;
			border-radius: 3px;
			border: 1px solid var(--adp-time-track-color);
			background: #fff;
			cursor: pointer;
			-webkit-transition: background var(--adp-transition-duration);
			transition: background var(--adp-transition-duration);
			margin-top: calc(var(--adp-time-thumb-size) / 2 * -1);
		}
		&::-ms-tooltip {
			display: none;
		}
		&:hover {
			&::-webkit-slider-thumb {
				border-color: $border-color_1;
			}
			&::-moz-range-thumb {
				border-color: $border-color_1;
			}
			&::-ms-thumb {
				border-color: $border-color_1;
			}
		}
		&:focus {
			outline: none;
			&::-webkit-slider-thumb {
				background: var(--adp-cell-background-color-selected);
				border-color: $border-color_2;
			}
			&::-moz-range-thumb {
				background: var(--adp-cell-background-color-selected);
				border-color: $border-color_2;
			}
			&::-ms-thumb {
				background: var(--adp-cell-background-color-selected);
				border-color: $border-color_2;
			}
		}
		&::-moz-range-thumb {
			box-sizing: border-box;
			height: 12px;
			width: 12px;
			border-radius: 3px;
			border: 1px solid var(--adp-time-track-color);
			background: #fff;
			cursor: pointer;
			-moz-transition: background var(--adp-transition-duration);
			transition: background var(--adp-transition-duration);
		}
		&::-ms-thumb {
			box-sizing: border-box;
			height: 12px;
			width: 12px;
			border-radius: 3px;
			border: 1px solid var(--adp-time-track-color);
			background: #fff;
			cursor: pointer;
			-ms-transition: background var(--adp-transition-duration);
			transition: background var(--adp-transition-duration);
		}
		&::-webkit-slider-runnable-track {
			border: none;
			height: var(--adp-time-track-height);
			cursor: pointer;
			color: $color_14;
			background: rgba(0, 0, 0, 0);
		}
		&::-moz-range-track {
			border: none;
			height: var(--adp-time-track-height);
			cursor: pointer;
			color: $color_14;
			background: rgba(0, 0, 0, 0);
		}
		&::-ms-track {
			border: none;
			height: var(--adp-time-track-height);
			cursor: pointer;
			color: $color_14;
			background: rgba(0, 0, 0, 0);
		}
		&::-ms-fill-lower {
			background: rgba(0, 0, 0, 0);
		}
		&::-ms-fill-upper {
			background: rgba(0, 0, 0, 0);
		}
	}
}
.air-datepicker {
	--adp-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--adp-font-size: 14px;
	--adp-width: 246px;
	--adp-z-index: 100;
	--adp-padding: 4px;
	--adp-grid-areas: "nav" "body" "timepicker" "buttons";
	--adp-transition-duration: .3s;
	--adp-transition-ease: ease-out;
	--adp-transition-offset: 8px;
	--adp-background-color: #fff;
	--adp-background-color-hover: #f0f0f0;
	--adp-background-color-active: #eaeaea;
	--adp-background-color-in-range: rgba(92, 196, 239, .1);
	--adp-background-color-in-range-focused: rgba(92, 196, 239, .2);
	--adp-background-color-selected-other-month-focused: #8ad5f4;
	--adp-background-color-selected-other-month: #a2ddf6;
	--adp-color: #4a4a4a;
	--adp-color-secondary: #9c9c9c;
	--adp-accent-color: #DB574D;
	--adp-color-current-date: var(--adp-accent-color);
	--adp-color-other-month: #dedede;
	--adp-color-disabled: #aeaeae;
	--adp-color-disabled-in-range: #939393;
	--adp-color-other-month-hover: #c5c5c5;
	--adp-border-color: #dbdbdb;
	--adp-border-color-inner: #efefef;
	--adp-border-radius: 4px;
	--adp-border-color-inline: #d7d7d7;
	--adp-nav-height: 32px;
	--adp-nav-arrow-color: var(--adp-color-secondary);
	--adp-nav-action-size: 32px;
	--adp-nav-color-secondary: var(--adp-color-secondary);
	--adp-day-name-color: #ff9a19;
	--adp-day-name-color-hover: #8ad5f4;
	--adp-day-cell-width: 1fr;
	--adp-day-cell-height: 32px;
	--adp-month-cell-height: 42px;
	--adp-year-cell-height: 56px;
	--adp-pointer-size: 10px;
	--adp-poiner-border-radius: 2px;
	--adp-pointer-offset: 14px;
	--adp-cell-border-radius: 4px;
	--adp-cell-background-color-hover: var(--adp-background-color-hover);
	--adp-cell-background-color-selected: #5cc4ef;
	--adp-cell-background-color-selected-hover: #45bced;
	--adp-cell-background-color-in-range: rgba(92, 196, 239, 0.1);
	--adp-cell-background-color-in-range-hover: rgba(92, 196, 239, 0.2);
	--adp-cell-border-color-in-range: var(--adp-cell-background-color-selected);
	--adp-btn-height: 32px;
	--adp-btn-color: var(--adp-accent-color);
	--adp-btn-color-hover: var(--adp-color);
	--adp-btn-border-radius: var(--adp-border-radius);
	--adp-btn-background-color-hover: var(--adp-background-color-hover);
	--adp-btn-background-color-active: var(--adp-background-color-active);
	--adp-time-track-height: 1px;
	--adp-time-track-color: #dedede;
	--adp-time-track-color-hover: #b1b1b1;
	--adp-time-thumb-size: 12px;
	--adp-time-padding-inner: 10px;
	--adp-time-day-period-color: var(--adp-color-secondary);
	--adp-mobile-font-size: 16px;
	--adp-mobile-nav-height: 40px;
	--adp-mobile-width: 320px;
	--adp-mobile-day-cell-height: 38px;
	--adp-mobile-month-cell-height: 48px;
	--adp-mobile-year-cell-height: 64px;
	background: var(--adp-background-color);
	border: 1px solid var(--adp-border-color);
	box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
	border-radius: var(--adp-border-radius);
	box-sizing: content-box;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4, max-content);
	grid-template-areas: var(--adp-grid-areas);
	font-family: $font-family_2;
	font-size: var(--adp-font-size);
	color: $color_4;
	width: var(--adp-width);
	position: absolute;
	transition: opacity var(--adp-transition-duration) var(--adp-transition-ease), transform var(--adp-transition-duration) var(--adp-transition-ease);
	z-index: var(--adp-z-index);
	&:not(.-custom-position-) {
		opacity: 0;
	}
}
.air-datepicker-overlay {
	--adp-overlay-background-color: rgba(0, 0, 0, .3);
	--adp-overlay-transition-duration: .3s;
	--adp-overlay-transition-ease: ease-out;
	--adp-overlay-z-index: 99;
	position: fixed;
	background: var(--adp-overlay-background-color);
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	opacity: 0;
	transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), left 0s, height 0s, width 0s;
	transition-delay: 0s, var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration);
	z-index: var(--adp-overlay-z-index);
}
.air-datepicker.-from-top- {
	transform: translateY(calc(var(--adp-transition-offset) * -1));
}
.air-datepicker.-from-right- {
	transform: translateX(var(--adp-transition-offset));
}
.air-datepicker.-from-bottom- {
	transform: translateY(var(--adp-transition-offset));
}
.air-datepicker.-from-left- {
	transform: translateX(calc(var(--adp-transition-offset) * -1));
}
.air-datepicker.-active- {
	&:not(.-custom-position-) {
		transform: translate(0, 0);
		opacity: 1;
	}
}
.air-datepicker.-active-.-custom-position- {
	transition: none;
}
.air-datepicker.-inline- {
	border-color: $border-color_3;
	box-shadow: none;
	position: static;
	left: auto;
	right: auto;
	opacity: 1;
	transform: none;
	.air-datepicker--pointer {
		display: none;
	}
}
.air-datepicker.-is-mobile- {
	--adp-font-size: var(--adp-mobile-font-size);
	--adp-day-cell-height: var(--adp-mobile-day-cell-height);
	--adp-month-cell-height: var(--adp-mobile-month-cell-height);
	--adp-year-cell-height: var(--adp-mobile-year-cell-height);
	--adp-nav-height: var(--adp-mobile-nav-height);
	--adp-nav-action-size: var(--adp-mobile-nav-height);
	position: fixed;
	width: var(--adp-mobile-width);
	border: none;
	* {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	.air-datepicker--pointer {
		display: none;
	}
	&:not(.-custom-position-) {
		transform: translate(-50%, calc(-50% + var(--adp-transition-offset)));
	}
}
.air-datepicker.-is-mobile-.-active- {
	&:not(.-custom-position-) {
		transform: translate(-50%, -50%);
	}
}
.air-datepicker.-custom-position- {
	transition: none;
}
.air-datepicker-global-container {
	position: absolute;
	left: 0;
	top: 0;
}
.air-datepicker--pointer {
	--pointer-half-size: calc(var(--adp-pointer-size) / 2);
	position: absolute;
	width: var(--adp-pointer-size);
	height: var(--adp-pointer-size);
	z-index: -1;
	&:after {
		content: "";
		position: absolute;
		background: #fff;
		border-top: 1px solid var(--adp-border-color-inline);
		border-right: 1px solid var(--adp-border-color-inline);
		border-top-right-radius: var(--adp-poiner-border-radius);
		width: var(--adp-pointer-size);
		height: var(--adp-pointer-size);
		box-sizing: border-box;
	}
}
.-top-left- {
	.air-datepicker--pointer {
		top: calc(100% - var(--pointer-half-size) + 1px);
		left: var(--adp-pointer-offset);
		&:after {
			transform: rotate(135deg);
		}
	}
}
.-top-center- {
	.air-datepicker--pointer {
		top: calc(100% - var(--pointer-half-size) + 1px);
		left: calc(50% - var(--adp-pointer-size) / 2);
		&:after {
			transform: rotate(135deg);
		}
	}
}
.-top-right- {
	.air-datepicker--pointer {
		top: calc(100% - var(--pointer-half-size) + 1px);
		right: var(--adp-pointer-offset);
		&:after {
			transform: rotate(135deg);
		}
	}
}
[data-popper-placement^=top] {
	.air-datepicker--pointer {
		top: calc(100% - var(--pointer-half-size) + 1px);
		&:after {
			transform: rotate(135deg);
		}
	}
}
.-right-top- {
	.air-datepicker--pointer {
		right: calc(100% - var(--pointer-half-size) + 1px);
		top: var(--adp-pointer-offset);
		&:after {
			transform: rotate(225deg);
		}
	}
}
.-right-center- {
	.air-datepicker--pointer {
		right: calc(100% - var(--pointer-half-size) + 1px);
		top: calc(50% - var(--adp-pointer-size) / 2);
		&:after {
			transform: rotate(225deg);
		}
	}
}
.-right-bottom- {
	.air-datepicker--pointer {
		right: calc(100% - var(--pointer-half-size) + 1px);
		bottom: var(--adp-pointer-offset);
		&:after {
			transform: rotate(225deg);
		}
	}
}
[data-popper-placement^=right] {
	.air-datepicker--pointer {
		right: calc(100% - var(--pointer-half-size) + 1px);
		&:after {
			transform: rotate(225deg);
		}
	}
}
.-bottom-left- {
	.air-datepicker--pointer {
		bottom: calc(100% - var(--pointer-half-size) + 1px);
		left: var(--adp-pointer-offset);
		&:after {
			transform: rotate(315deg);
		}
	}
}
.-bottom-center- {
	.air-datepicker--pointer {
		bottom: calc(100% - var(--pointer-half-size) + 1px);
		left: calc(50% - var(--adp-pointer-size) / 2);
		&:after {
			transform: rotate(315deg);
		}
	}
}
.-bottom-right- {
	.air-datepicker--pointer {
		bottom: calc(100% - var(--pointer-half-size) + 1px);
		right: var(--adp-pointer-offset);
		&:after {
			transform: rotate(315deg);
		}
	}
}
[data-popper-placement^=bottom] {
	.air-datepicker--pointer {
		bottom: calc(100% - var(--pointer-half-size) + 1px);
		&:after {
			transform: rotate(315deg);
		}
	}
}
.-left-top- {
	.air-datepicker--pointer {
		left: calc(100% - var(--pointer-half-size) + 1px);
		top: var(--adp-pointer-offset);
		&:after {
			transform: rotate(45deg);
		}
	}
}
.-left-center- {
	.air-datepicker--pointer {
		left: calc(100% - var(--pointer-half-size) + 1px);
		top: calc(50% - var(--adp-pointer-size) / 2);
		&:after {
			transform: rotate(45deg);
		}
	}
}
.-left-bottom- {
	.air-datepicker--pointer {
		left: calc(100% - var(--pointer-half-size) + 1px);
		bottom: var(--adp-pointer-offset);
		&:after {
			transform: rotate(45deg);
		}
	}
}
[data-popper-placement^=left] {
	.air-datepicker--pointer {
		left: calc(100% - var(--pointer-half-size) + 1px);
		&:after {
			transform: rotate(45deg);
		}
	}
}
.air-datepicker--navigation {
	grid-area: nav;
}
.air-datepicker--content {
	box-sizing: content-box;
	padding: var(--adp-padding);
	grid-area: body;
}
.air-datepicker--time {
	grid-area: timepicker;
	padding: var(--adp-padding);
	border-top: 1px solid var(--adp-border-color-inner);
}
.air-datepicker--buttons {
	grid-area: buttons;
	padding: var(--adp-padding);
	border-top: 1px solid var(--adp-border-color-inner);
}
.air-datepicker-overlay.-active- {
	opacity: 1;
	width: 100%;
	height: 100%;
	transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), height 0s, width 0s;
}
