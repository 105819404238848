.requests-section {


	&__inner {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: clamp(25px, 6.51vw, 70px);

		padding-top: clamp(50px, 9.9vw, 140px);

		@include for-small-tablet {
			padding-bottom: calc(clamp(50px, 10.9375vw, 150px) / 2);
		}

		@include small-tablet {
			padding-bottom: calc(clamp(150px, 23.4375vw, 200px) - 44px);
		}

		@include mobile {
			padding-bottom: calc(clamp(150px, 23.4375vw, 200px) + 75px);
		}
	}

	.round {
		max-width: clamp(150px, 23.4375vw, 200px);
		width: 100%;
		padding: 20px;
		right: 2%;
		top: 10px;
		position: absolute;

		@include small-tablet {
			top: initial;
			bottom: -30px;
			z-index: 20;

			&:before {
				width: 100%;
				left: 10px;
				bottom: 10px;
			}
		}

		@include mobile {
			bottom: calc(clamp(150px, 23.4375vw, 200px) * .5);
			right: 7%;
		}
	}

	.main-top {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		max-width: calc(100% - clamp(150px, 22.79vw, 200px));

		@include mobile {
			align-items: flex-start;
			max-width: 100%;
		}
	}


}
